import React from 'react';
import NavbarThree from "../components/App/NavbarThree"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import MainBanner from '../components/Index/MainBanner';
import OurSolutions from '../components/Index/OurSolutions';
import AboutArea from '../components/Index/AboutArea';
import Funfacts from '../components/Common/Funfacts';
import HowItWork from '../components/Index/HowItWork';
import StartProjectTwo from '../components/Common/StartProjectTwo';


const Home = () => {
    return (
        <Layout>
            <NavbarThree />
            <MainBanner />
            <OurSolutions />
            <AboutArea />          
            <HowItWork />
            <Funfacts />
            <StartProjectTwo />
            <Footer />
        </Layout>
    );
}

export default Home;