import React from 'react';
import {Link} from 'gatsby'

import StarIcon from '../../assets/images/star-icon.png'

const OurSolutions = () => {
    return (
        <section className="solutions-area pt-100 pb-70">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={StarIcon} alt="image" /> 
                        Our approach
                    </span>
                    <h2>Why you should work with us</h2>
                    <p>We are the best fit for you if you have a SaaS project to develop or maintain.</p>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-rocket"></i>
                            </div>
                            <h3>
                                <Link to="/service-details">
                                    Code Security
                                </Link>
                            </h3>
                            <p>We use state-of-the-art tools and practices to guarantee the security of your data.</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-laptop"></i>
                            </div>

                            <h3>
                                <Link to="/service-details">
                                    SaaS Solutions
                                </Link>
                            </h3>

                            <p>We specialize in the development and maintenance of SaaS solutions. Your software project is in good hands.</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6 offset-lg-0 offset-sm-3">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-analytics"></i>
                            </div>

                            <h3>
                                <Link to="/service-details">
                                    Analytics
                                </Link>
                            </h3> 

                            <p>You always have up-to-date information about the performance of your project. We use only transparent project management practices.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OurSolutions;