import React from 'react';

import StarIcon from '../../assets/images/star-icon.png'
import HowitsWork from '../../assets/images/how-its-work.png'

const HowItWork = () => {
    return (
        <section className="how-its-work-area ptb-100">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="how-its-work-content">
                            <span className="sub-title">
                                <img src={StarIcon} alt="image" /> 
                                How It's Work
                            </span>
                            <h2>How we deal with it</h2>
                            <p>Smooth and timely communication is the key to a successful project.</p>
                            <div className="inner-box">
                                <div className="single-item">
                                    <div className="count-box">
                                        1
                                    </div>
                                    <h3>Initial vision</h3>
                                    <p>It is important to communicate the correct vision of a project at the beginning.</p>
                                </div>
                                <div className="single-item">
                                    <div className="count-box">
                                        2
                                    </div>
                                    <h3>Agile project management</h3>
                                    <p>We don't like the waterfall plan. It never works for SaaS solutions. We stick to agile.</p>
                                </div>
                                <div className="single-item">
                                    <div className="count-box">
                                        3
                                    </div>
                                    <h3>Continuous improvement</h3>
                                    <p>We increase SaaS project ARR by increasing its value for end users.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="how-its-work-image">
                            <img src={HowitsWork} alt="image" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HowItWork;